import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F5F4F4',
      neutralLight2: '#EDE9E5',
      neutralLight1: '#D6CDC4',
      neutralDark6: '#707070',
      neutralDark5: '#6C6C6C',
      neutralDark4: '#41424A',
      neutralDark3: '#312A23',
      neutralDark2: '#14161E',
      neutralDark1: '#000000',
      primaryDark: '#00435A',
      primaryLight: '#004E68',
      secondaryLight: '#B28E6D',
      secondaryDark: '#98775A',
      successLight: '#35563C',
      successDark: '#325038',
      dangerLight: '#CC2121',
    },
  },
  fontFamily: {
    heading: "'Cinzel', serif",
    paragraph: "'Poppins', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
