exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-academy-page-index-tsx": () => import("./../../../src/templates/AcademyPage/index.tsx" /* webpackChunkName: "component---src-templates-academy-page-index-tsx" */),
  "component---src-templates-beach-page-index-tsx": () => import("./../../../src/templates/BeachPage/index.tsx" /* webpackChunkName: "component---src-templates-beach-page-index-tsx" */),
  "component---src-templates-club-house-page-index-tsx": () => import("./../../../src/templates/ClubHousePage/index.tsx" /* webpackChunkName: "component---src-templates-club-house-page-index-tsx" */),
  "component---src-templates-course-page-index-tsx": () => import("./../../../src/templates/CoursePage/index.tsx" /* webpackChunkName: "component---src-templates-course-page-index-tsx" */),
  "component---src-templates-day-spa-page-index-tsx": () => import("./../../../src/templates/DaySpaPage/index.tsx" /* webpackChunkName: "component---src-templates-day-spa-page-index-tsx" */),
  "component---src-templates-downloads-page-index-tsx": () => import("./../../../src/templates/DownloadsPage/index.tsx" /* webpackChunkName: "component---src-templates-downloads-page-index-tsx" */),
  "component---src-templates-event-page-index-tsx": () => import("./../../../src/templates/EventPage/index.tsx" /* webpackChunkName: "component---src-templates-event-page-index-tsx" */),
  "component---src-templates-events-page-index-tsx": () => import("./../../../src/templates/EventsPage/index.tsx" /* webpackChunkName: "component---src-templates-events-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-golf-offers-page-index-tsx": () => import("./../../../src/templates/GolfOffersPage/index.tsx" /* webpackChunkName: "component---src-templates-golf-offers-page-index-tsx" */),
  "component---src-templates-gym-page-index-tsx": () => import("./../../../src/templates/GymPage/index.tsx" /* webpackChunkName: "component---src-templates-gym-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-info-page-index-tsx": () => import("./../../../src/templates/InfoPage/index.tsx" /* webpackChunkName: "component---src-templates-info-page-index-tsx" */),
  "component---src-templates-meeting-page-index-tsx": () => import("./../../../src/templates/MeetingPage/index.tsx" /* webpackChunkName: "component---src-templates-meeting-page-index-tsx" */),
  "component---src-templates-offer-page-index-tsx": () => import("./../../../src/templates/OfferPage/index.tsx" /* webpackChunkName: "component---src-templates-offer-page-index-tsx" */),
  "component---src-templates-offers-page-index-tsx": () => import("./../../../src/templates/OffersPage/index.tsx" /* webpackChunkName: "component---src-templates-offers-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-pool-page-index-tsx": () => import("./../../../src/templates/PoolPage/index.tsx" /* webpackChunkName: "component---src-templates-pool-page-index-tsx" */),
  "component---src-templates-pro-shop-page-index-tsx": () => import("./../../../src/templates/ProShopPage/index.tsx" /* webpackChunkName: "component---src-templates-pro-shop-page-index-tsx" */),
  "component---src-templates-quotation-page-index-tsx": () => import("./../../../src/templates/QuotationPage/index.tsx" /* webpackChunkName: "component---src-templates-quotation-page-index-tsx" */),
  "component---src-templates-rates-page-index-tsx": () => import("./../../../src/templates/RatesPage/index.tsx" /* webpackChunkName: "component---src-templates-rates-page-index-tsx" */),
  "component---src-templates-resort-page-index-tsx": () => import("./../../../src/templates/ResortPage/index.tsx" /* webpackChunkName: "component---src-templates-resort-page-index-tsx" */),
  "component---src-templates-restaurant-page-index-tsx": () => import("./../../../src/templates/RestaurantPage/index.tsx" /* webpackChunkName: "component---src-templates-restaurant-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-route-page-index-tsx": () => import("./../../../src/templates/RoutePage/index.tsx" /* webpackChunkName: "component---src-templates-route-page-index-tsx" */),
  "component---src-templates-summer-sports-page-index-tsx": () => import("./../../../src/templates/SummerSportsPage/index.tsx" /* webpackChunkName: "component---src-templates-summer-sports-page-index-tsx" */),
  "component---src-templates-wedding-page-index-tsx": () => import("./../../../src/templates/WeddingPage/index.tsx" /* webpackChunkName: "component---src-templates-wedding-page-index-tsx" */),
  "component---src-templates-winter-sports-page-index-tsx": () => import("./../../../src/templates/WinterSportsPage/index.tsx" /* webpackChunkName: "component---src-templates-winter-sports-page-index-tsx" */)
}

